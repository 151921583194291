<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col cols="12" xl="6" class="pl-2 mb-2">
                <ValidationProvider
                  name="No SPPD"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <h6 class="mb-25">
                    Pilih No SPPD <span class="fs-6 text-danger">*</span>
                  </h6>
                  <v-select
                    v-model="model.sppd_id"
                    @input="getDetailSppd(model.sppd_id)"
                    :options="sppd"
                    :reduce="item => item.id"
                    label="sppd_number"
                  />
                  <p class="fs-6 text-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" xl="6" class="pl-2 mb-2">
                <h6>
                  {{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama
                </h6>
                <p class="card-text mb-25">
                  {{
                    employee.type === 'pegawai' ? employee.nippos : employee.nik
                  }}
                  /
                  {{
                    employee.type === 'pegawai' ? employee.nama : employee.name
                  }}
                </p>
              </b-col>
              <b-col cols="12" xl="6" class="pl-2 mb-2">
                <h6 class="mb-25">Jenis Fasilitas</h6>
                <p class="card-text mb-25">
                  {{ sppdFacilityType }}
                </p>
              </b-col>
              <b-col cols="12" xl="6" class="pl-2 mb-2">
                <h6 class="mb-25">Jabatan</h6>
                <p class="card-text mb-25">
                  {{
                    employee.type === 'pegawai'
                      ? employee.descjabatan
                      : employee.position
                  }}
                </p>
              </b-col>
              <b-col cols="12" xl="6" class="pl-2 mb-2">
                <h6 class="mb-25">Divisi</h6>
                <p class="card-text mb-25">
                  {{ detail.division != '' ? detail.division : '-' }}
                </p>
              </b-col>
            </b-row>
            <hr />
            <div>
              <!-- Row Loop -->
              <b-row v-for="(page, idx) in model.real_expenses" :key="idx">
                <b-col md="2">
                  <ValidationProvider
                    name="Komponen Biaya"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="name">
                        Komponen Biaya <span class="fs-6 text-danger">*</span>
                      </label>
                      <v-select
                        v-model="model.real_expenses[idx].cost_component_id"
                        :options="cost"
                        :reduce="item => item.id"
                        label="name"
                      />
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider
                    name="Biaya"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="name">
                        Biaya <span class="fs-6 text-danger">*</span>
                      </label>
                      <vue-number
                        v-model="model.real_expenses[idx].total_cost"
                        id="total_cost"
                        class="form-control"
                        placeholder="Biaya"
                        prefix= "Rp "
                        @keypress.native="NumbersOnly"
                      ></vue-number>
                      <!-- <vue-numeric
                        v-model="model.real_expenses[idx].total_cost"
                        name="total_cost"
                        @keypress.native="NumbersOnly"
                        class="form-control"
                        placeholder="Biaya"
                        currency="Rp"
                        separator="."
                      ></vue-numeric> -->
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider
                    name="Catatan"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="name">
                        Catatan <span class="fs-6 text-danger">*</span>
                      </label>
                      <b-form-textarea
                        id="note"
                        v-model="model.real_expenses[idx].note"
                        placeholder="Catatan"
                      />
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider
                    name="Kwitansi"
                    rules="required"
                    v-slot="{ errors }"
                    class="col"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label>
                        Upload Kwitansi <span class="fs-6 text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, pdf, doc, docx"
                        @change="storeUpload($event, idx)"
                        name="receipt"
                        class="form-control"
                      />
                      <input
                        type="hidden"
                        id="mc-name"
                        v-model="model.real_expenses[idx].receipt"
                      />
                      <b-link
                        v-if="model.real_expenses[idx].receipt"
                        :href="model.real_expenses[idx].receipt"
                        target="_blank"
                        class="d-block text-info mt-1"
                      >
                        {{ model.real_expenses[idx].receipt.split('/').at(-1) }}
                      </b-link>
                      <p class="text-gray mb-0" style="font-size: 0.857rem">Unggah file dalam format JPG, PNG, GIF, PDF, DOCX, atau DOC (batas maks. 10 MB).</p>
                      <p class="fs-6 text-danger">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </b-col>
                <b-col md="1">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-3"
                    @click="removeItem(idx)"
                    v-if="model.real_expenses.length > 1"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-col>
                <br />
              </b-row>
              <b-button
                type="button"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgain()"
                class="mb-2"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </div>
            <b-card-footer>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <!-- back -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'pengeluaran-rill' }"
              >
                Kembali
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'
import VueNumberFormat from '@core/components/vue-number-format'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BTable,
    VueNumberFormat,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      name: 'UsersForm',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/real-expenses',
        sppd: '/misc/sppds',
        detailsppd: '/sppd/detail_info/',
        cost: '/misc/cost-components',
        redirect: 'pengeluaran-rill',
      },
      model: {
        sppd_id: '',
        real_expenses: [
          {
            cost_component_id: null,
            note: '',
            total_cost: '',
            receipt: '',
          },
        ],
      },
      isLoading: false,
      sppd: [],
      detail: [],
      cost: [],
      messages: '',
      folderName: '',
    }
  },
  computed: {
    sendDisabledState() {
      if (!this.selectedTravelAgent) {
        return this.real_expenses.length === 0 || !this.kwitansi
      }
      return false
    },
    sppdFacilityType() {
      if (this.detail.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.detail.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.detail.employee?.raw
        ? JSON.parse(this.detail.employee.raw)
        : {}
    },
  },
  mounted() {
    let _ = this
    _.getSppd()
    _.getCost()
  },
  methods: {
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    storeUpload(event, idx) {
      this.model.real_expenses[idx].receipt = event.target.files[0]
      this.isLoading = true
      let formData = new FormData()
      formData.append('file', this.model.real_expenses[idx].receipt)
      formData.append('folder', this.folderName)
      formData.append('type', 'pengeluaran rill')
      axios
        .post('/misc/upload-file', formData)
        .then(res => {
          this.model.real_expenses[idx].receipt = res.data.filename
        })
        .catch(err => {
          this.errors = 'Kwitansi gagal di upload'
        })
    },
    repeateAgain() {
      let _ = this
      _.model.real_expenses.push({
        cost_component_id: null,
        note: '',
        total_cost: '',
        receipt: '',
      })
    },
    removeItem(index) {
      this.model.real_expenses.splice(index, 1)
    },
    getSppd() {
      let _ = this
      axios.get(_.config.sppd).then(response => {
        let _ = this
        _.sppd = response.data.data
      })
      .catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    getCost() {
      let _ = this
      axios.get(_.config.cost).then(response => {
        let _ = this
        _.cost = response.data.data
      })
      .catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    getDetailSppd(id) {
      let _ = this
      axios.get(_.config.detailsppd + id).then(response => {
        let _ = this
        _.detail = response.data.data
        _.folderName = _.detail.folder
      })
      .catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    save() {
      let _ = this
      if (this.$route.meta.action == 'store') {
        axios
          .post(_.config.api, _.model)
          .then(res => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: 'success',
                title: 'Tambah Data Berhasil',
                text: 'Data baru berhasil ditambahkan',
              },
            })
          })
          .catch(e => {
            // console.log(e.response.data.message.errors[0].msg);
            let vm = this
            vm.showDismissibleAlert = true
            if (typeof e.response.data.message === 'object') {
              vm.messages = e.response.data.message.errors[0].msg
              vm.errors = e.response.data.data
            } else {
              vm.messages = e.response.data.message
              vm.errors = e.response.data.data
            }
          })
      } else {
        _.model.id = _.$route.params.id
        axios
          .put(_.config.api + '/' + _.$route.params.id, _.model)
          .then(res => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: 'success',
                title: 'Ubah Data Berhasil',
                text: 'Data berhasil diubah',
              },
            })
          })
          .catch(e => {
            let vm = this
            vm.showDismissibleAlert = true
            if (typeof e.response.data.message === 'object') {
              vm.messages = e.response.data.message.errors[0].msg
              vm.errors = e.response.data.data
            } else {
              vm.messages = e.response.data.message
              vm.errors = e.response.data.data
            }
          })
      }
    },
  },
}
</script>
